<template>
  <div id="badge-history-id" v-loading.fullscreen.lock="loading">
    <defaultTemplate>
      <div
        class="pd-x-5 pd-y-3"
        v-if="BadgeById && BadgeById.badges.length > 0"
      >
        <el-breadcrumb separator-class="el-icon-arrow-right" class="">
          <el-breadcrumb-item :to="{ path: `/badges-history` }">
            <span class="text-all font-12">Badge History</span>
          </el-breadcrumb-item>
          <el-breadcrumb-item
            ><span class="font-12 text-breadcrumb"
              >{{ BadgeById.courseName }} ({{ BadgeById.course }})
            </span></el-breadcrumb-item
          >
        </el-breadcrumb>

        <div class="font-14 mg-b-5 mg-t-3 font-weight-500 text-center color-00">
          {{ BadgeById.courseName }} ({{ BadgeById.course }})
        </div>
        <div>
          <carousel :responsive="responsive" :nav="false">
            <div
              class="card mg-6 primary"
              v-for="(item, index) in groupType"
              :key="index"
            >
              <div @click="selectBadgeDefault(item)">
                <p class="mg-b-7 font-12 color-blue-dark font-weight-500">
                  {{ item.courseId }}.{{ item.skillType }}
                </p>
                <p class="mg-b-7 font-10 color-blue-dark font-weight-500">
                  {{ getLearningHour(item.data) }}
                  {{
                    getLearningHour(item.data) > 1
                      ? " Learning hours"
                      : " Learning hour"
                  }}
                </p>
              </div>
            </div>
          </carousel>
        </div>

        <!-- <pre>
            {{ selectBadgeDetail }}
          </pre
        > -->
        <div>
          <div class="is-flex js-between pd-t-5">
            <p class="font-14 color-35 pd-t-5 font-weight-500">
              {{ selectBadgeDetail.courseId }}.{{ selectBadgeDetail.skillType }}
            </p>
            <p class="font-12 color-blue-light pd-t-5 font-weight-400">
              {{ getLearningHour(selectBadgeDetail.data) }}
              {{
                getLearningHour(selectBadgeDetail.data) > 1
                  ? " Learning hours"
                  : " Learning hour"
              }}
            </p>
          </div>

          <div v-for="(badge, index) in selectBadgeDetail.data" :key="index">
            <!-- <pre>
              {{ badge }}
            </pre> -->
            <router-link
              :to="`/badges-history/${badgeById}/${badge._id}`"
              class="cursor-pointer"
            >
              <div class="card mg-y-7">
                <div class="is-flex js-between ai-center">
                  <div>
                    <p class="font-12 mg-b-0 name-sub font-weight-500">
                      {{ badge.topic }}
                    </p>

                    <div class="is-flex ai-center name-sub">
                      <p class="mg-0 mg-t-7 font-10 mg-r-7">
                        {{ badge.learningHour }}
                        {{
                          badge.learningHour > 1
                            ? " Learning hours"
                            : " Learning hour"
                        }}
                      </p>
                      <!-- <p class="mg-0 mg-t-7 font-10 color-blue mg-r-7">
                        {{ " " + " • Session Name : " + badge.sessionName }}
                      </p>
                      <p
                        class="
                          color-blue
                          font-10
                          mg-0
                          font-weight-400
                          mg-t-7 mg-r-7
                        "
                      >
                        {{ "• Activity : " + badge.activityDescription }}
                      </p> -->
                    </div>
                  </div>
                  <p class="color-blue-dark font-12 mg-b-6">
                    <i class="fas fa-chevron-right"></i>
                  </p>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <!-- <div v-else class="text-center mg-t-2">
        <p class="font-22 font-weight-500">ไม่พบข้อมูล Badge</p>
      </div> -->
    </defaultTemplate>
  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import { HTTP } from "@/service/axios";
import defaultTemplate from "@/template/default.vue";
export default {
  name: "BadgeHistoryById",
  components: {
    defaultTemplate,
    carousel
  },
  data() {
    return {
      groupType: null,
      loading: false,
      BadgeById: null,
      selectBadgeDetail: null,
      responsive: {
        // breakpoint from 0 up
        0: {
          items: 2
          //loop: true,
          // option1 : value,
          // option2 : value,
        },
        // breakpoint from 768 up
        767: {
          items: 2
        }
      }
    };
  },
  mounted() {
    this.getBadgesHistory();
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    badgeById() {
      return this.$route.params.id;
    }
  },
  methods: {
    getBadgesHistory() {
      this.loading = true;
      console.log("getBadgesHistory");
      HTTP.defaults.headers.common.Authorization =
        `Bearer ` + this.user.accessToken;
      HTTP.get(`badges/history`)

        .then(res => {
          if (res.data.success) {
            let BadgesHistory = res.data.obj;
            console.log(BadgesHistory, "BadgesHistory");
            let filter = BadgesHistory.filter(
              item => item.courseId == this.badgeById
            );
            if (filter.length > 0) {
              this.BadgeById = filter[0];

              // -------------------------  set 0
              if (this.BadgeById && this.BadgeById.badges.length > 0) {
                // this.selectBadgeDefault(0, this.BadgeById.badges[0]._id);

                let arrType = [];
                this.BadgeById.badges.forEach(ft1 => {
                  let arrTypeFil = arrType.filter(
                    arrFilter => arrFilter.skillType == ft1.skillType
                  );
                  if (arrTypeFil.length == 0) {
                    let badgeFilter = this.BadgeById.badges.filter(
                      badgeFil => badgeFil.skillType == ft1.skillType
                    );
                    let obj = {
                      skillType: ft1.skillType,
                      data: badgeFilter,
                      courseId: ft1.courseId
                    };
                    arrType.push(obj);
                  }
                });
                this.groupType = arrType;
                this.selectBadgeDetail = this.groupType[0];
                console.log(arrType, "arrType ja");
              }

              // -------------------------  end set 0
            } else {
              this.BadgeById = null;
            }
          }
        })
        .catch(e => {
          console.log(e);
          this.alertCatchError(e.response.status);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    selectBadgeDefault(skillType) {
      this.selectBadgeDetail = skillType;
    },
    getLearningHour(badgedata) {
      let sumHour = 0;
      badgedata.map(item => {
        sumHour = Number(item.learningHour) + sumHour;
      });
      return sumHour;
    }
  }
};
</script>

<style></style>
